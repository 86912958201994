import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import baseUrl from "../Api";
import moment from "moment";

const detailsBg = {
  backgroundImage: "url(images/banner/banner09.jpg)",
};

const EventSub = () => {
  const [eventdet, setEventDet] = useState("");
  const [revent, setREvent] = useState([]);

  const { id } = useParams();
  useEffect(() => {
    fetchEvent();
  }, [id]);

  const fetchEvent = async () => {
    setREvent([]);
    try {
      const response = await fetch(baseUrl + `/events/${id}?populate=*`);
      const data = await response.json();
      if (data.data !== null) {
        setEventDet(data.data);
        const eventRangeAmounts = data.data.attributes.event_range_amounts.data;

        if (eventRangeAmounts && eventRangeAmounts.length > 0) {
          const newREventData = [];
          for (let i = 0; i < eventRangeAmounts.length; i++) {
            const response = await fetch(
              `${baseUrl}/event-range-amounts/${eventRangeAmounts[i].id}?populate=*`
            );
            const data1 = await response.json();

            if (data1.data) {
              newREventData.push(data1.data); // Append new data to existing state
            }
          }
          setREvent((prevREvent) => [...prevREvent, ...newREventData]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      {eventdet && (
        <section className="details-banner" style={detailsBg}>
          <div className="container">
            <div className="details-banner-wrapper">
              <div className="details-banner-thumb">
                {eventdet.attributes.Thumbnail.data !== null && (
                  <img
                    src={`https://strapi.sportingindia.com${
                      eventdet.attributes.Thumbnail?.data?.attributes?.url ||
                      require("../assets/images/sporting.jpeg")
                    }`}
                    alt={eventdet.attributes.Name}
                    width={"255px"}
                    height={"357px"}
                  />
                )}
                {eventdet.attributes.Thumbnail.data === null && (
                  <img
                    src={require("../assets/images/sporting.jpeg")}
                    alt={eventdet.attributes.Name}
                    width={"255px"}
                    height={"357px"}
                  />
                )}
              </div>
              <div className="details-banner-content offset-lg-3">
                <h3 className="title">
                  {eventdet.attributes.Name || "Event Title"}
                </h3>
                <div className="tags">
                  <a>
                    Organized by:{" "}
                    <span>
                      {eventdet.attributes.organizedby || "Organizer Name"}
                    </span>
                  </a>
                </div>

                {revent && (
                  <>
                    {revent.map((event) => (
                      <a className="button" key={event.id}>
                        {event.attributes.event_range.data.attributes.Name}
                      </a>
                    ))}
                  </>
                )}

                <div className="social-and-duration">
                  <div className="duration-area">
                    <div className="item">
                      <i className="fas fa-calendar-alt"></i>
                      <span>
                        {moment(eventdet.attributes.startdate).format(
                          "DD MMM, YYYY"
                        )}
                      </span>
                    </div>
                    <div className="item">
                      <i className="fa-solid fa-map-location-dot"></i>
                      <span>{eventdet.attributes.location || "Location"}</span>
                    </div>
                  </div>

                  <ul className="social-share">
                    <div className="sharename">Share with</div>
                    <li>
                      {eventdet.attributes.facebook ? (
                        <a href={eventdet.attributes.facebook} target="_blank">
                          <i className="fa-brands fa-facebook"></i>
                        </a>
                      ) : (
                        <a>
                          <i className="fa-brands fa-facebook"></i>
                        </a>
                      )}
                    </li>
                    <li>
                      {eventdet.attributes.twitter ? (
                        <a href={eventdet.attributes.twitter} target="_blank">
                          <i className="fa-brands fa-x-twitter"></i>
                        </a>
                      ) : (
                        <a>
                          <i className="fa-brands fa-x-twitter"></i>
                        </a>
                      )}
                    </li>
                    <li>
                      {eventdet.attributes.linkedin ? (
                        <a href={eventdet.attributes.linkedin} target="_blank">
                          <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                      ) : (
                        <a>
                          <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                      )}
                    </li>
                    <li>
                      {eventdet.attributes.instagram ? (
                        <a href={eventdet.attributes.instagram} target="_blank">
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      ) : (
                        <a>
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default EventSub;

import React, { useEffect, useState } from "react";
import EventSub from "../components/EventSub";
import { Link, useNavigate, useParams } from "react-router-dom";
import baseUrl from "../Api";
import axios from "axios";
import moment from "moment";

const EventBooking = () => {
  const [eventdet, setEventDet] = useState("");
  const [revent, setREvent] = useState([]);
  const [rdate, setRDate] = useState([]);
  const navigate = useNavigate();
  const [subtot, setSubTot] = useState(0);
  const [subgst, setSubGst] = useState(0);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    if (
      localStorage.getItem("Token") === null ||
      localStorage.getItem("UID") === null
    ) {
      navigate("/login");
    }
    setSubTot(0);
    setSubGst(0);
    fetchEvent();
  }, [id]);

  const fetchEvent = async () => {
    setREvent([]);
    setRDate([]);
    setSelectedEvents([]);
    setLoading(true);
    try {
      const response = await fetch(baseUrl + `/events/${id}?populate=*`);
      const data = await response.json();

      if (data.data !== null) {
        setEventDet(data.data);
        const eventRangeAmounts = data.data.attributes.event_range_amounts.data;
        const eventDataRange =
          data.data.attributes.event_category_date_ranges.data;

        if (eventRangeAmounts && eventRangeAmounts.length > 0) {
          const newREventData = [];
          const arr = [];

          // Fetch event range amounts data
          for (let i = 0; i < eventRangeAmounts.length; i++) {
            const response = await fetch(
              `${baseUrl}/event-range-amounts/${eventRangeAmounts[i].id}?populate=*`
            );
            const data1 = await response.json();

            if (data1.data) {
              newREventData.push(data1.data); // Append new data to existing state
            }
          }
          setREvent((prevREvent) => [...prevREvent, ...newREventData]);

          if (newREventData.length !== 0) {
            const newRDate = [];

            if (eventDataRange && eventDataRange.length > 0) {
              for (let i = 0; i < eventDataRange.length; i++) {
                const response = await fetch(
                  `${baseUrl}/event-category-date-ranges/${eventDataRange[i].id}?populate=*`
                );
                const data1 = await response.json();

                if (data1.data) {
                  newREventData.forEach((eventData) => {
                    const eventAgeCategories =
                      eventData.attributes.age_categories.data;

                    eventAgeCategories.forEach((ageCategory) => {
                      // Loop through age categories from event-category-date-ranges
                      data1.data.attributes.age_categories.data.forEach(
                        (ageCategoryDate) => {
                          if (ageCategory.id === ageCategoryDate.id) {
                            // Store the data in newRDate
                            newRDate.push({
                              ...data1.data, // Add all the existing data from event-category-date-range
                              startDate: data1.data.attributes.startdate, // Store start date
                              endDate: data1.data.attributes.enddate, // Store end date
                            });
                          }
                        }
                      );
                    });
                  });
                }
              }
              setRDate((prevRDate) => [...prevRDate, ...newRDate]);

              for (let i = 0; i < newREventData.length; i++) {
                let startDate = "";
                let endDate = "";
                if (newRDate && newRDate.length > 0) {
                  for (let j = 0; j < newRDate.length; j++) {
                    if (
                      newRDate[j].attributes.age_categories.data &&
                      newRDate[j].attributes.age_categories.data.length > 0
                    ) {
                      newREventData[i].attributes.age_categories.data.forEach(
                        (ageCategoryFromEvent) => {
                          newRDate[j].attributes.age_categories.data.forEach(
                            (ageCategoryFromDate) => {
                              if (
                                ageCategoryFromEvent.id ===
                                ageCategoryFromDate.id
                              ) {
                                startDate = newRDate[j].attributes.startdate;
                                endDate = newRDate[j].attributes.enddate;
                              }
                            }
                          );
                        }
                      );
                    }
                  }
                  const categoryNames = newREventData[
                    i
                  ].attributes.age_categories.data
                    .map((item) => item.attributes.name) // Extract the name from each 'attributes' object
                    .join(", ");
                  arr.push({
                    id: newREventData[i].id,
                    kmsName:
                      newREventData[i].attributes.event_range.data.attributes
                        .Name,
                    price: newREventData[i].attributes.amount,
                    gst: newREventData[i].attributes.GST,
                    numberOfPersons: 1,
                    selected: false,
                    agecategory: categoryNames || "",
                    perdet: [
                      {
                        name: "",
                        dob: "",
                        gender: "",
                        tshirt: "",
                        phone: "",
                        email: "",
                      },
                    ],
                    total: 0,
                    totalgst: 0,
                    startDate: startDate || "",
                    endDate: endDate || "",
                  });
                }
              }
              setSelectedEvents(arr);
            }
          }
        }

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const [selectedEvents, setSelectedEvents] = useState([]);

  const handleAdd = (evt) => {
    if (selectedEvents.length !== 0) {
      for (let i = 0; i < selectedEvents.length; i++) {
        if (selectedEvents[i].id === evt) {
          selectedEvents[i].selected = !selectedEvents[i].selected;
          setSelectedEvents([...selectedEvents]);
        }
      }
      calculateTotals();
    }
  };

  const AddInc = (evt) => {
    if (selectedEvents.length !== 0) {
      for (let i = 0; i < selectedEvents.length; i++) {
        if (selectedEvents[i].id === evt) {
          const newPersonDetail = {
            name: "",
            dob: "",
            gender: "",
            tshirt: "",
            phone: "",
            email: "",
          };

          selectedEvents[i].numberOfPersons =
            selectedEvents[i].numberOfPersons + 1;
          selectedEvents[i].perdet.push(newPersonDetail);
          setSelectedEvents([...selectedEvents]);
        }
        calculateTotals();
      }
    }
  };
  const AddDec = (evt) => {
    if (selectedEvents.length !== 0) {
      for (let i = 0; i < selectedEvents.length; i++) {
        if (
          selectedEvents[i].id === evt &&
          selectedEvents[i].numberOfPersons > 1
        ) {
          selectedEvents[i].numberOfPersons =
            selectedEvents[i].numberOfPersons - 1;
          selectedEvents[i].perdet.splice(-1, 1);
          setSelectedEvents([...selectedEvents]);
        }
      }
      calculateTotals();
    }
  };

  function calculateTotals() {
    let subtotal = 0;
    let totalGST = 0;
    let esub = 0;
    let egst = 0;
    setSubTot(0);
    setSubGst(0);
    selectedEvents.forEach((event) => {
      if (event.selected) {
        const eventTotal = event.price * event.numberOfPersons;
        subtotal += eventTotal;

        const gstAmount = (eventTotal * event.gst) / 100;
        totalGST += gstAmount;

        event.total = eventTotal.toFixed(2);
        event.totalgst = gstAmount.toFixed(2);

        esub += eventTotal;
        egst += gstAmount;
      }
    });
    setSubTot(esub.toFixed(2));
    setSubGst(egst.toFixed(2));
  }

  const [emailError, setEmailError] = useState("");

  const validateEmail = (email, itemId, index) => {
    if (email) {
      let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!regEmail.test(email)) {
        setEmailError((prevErrors) => ({
          ...prevErrors,
          [`${itemId}-${index}`]: "Please enter a valid email address",
        }));
      } else {
        // setEmailError((prevErrors) => {
        //   const newErrors = { ...prevErrors };
        //   delete newErrors[`${itemId}-${index}`]; // Remove error for this person
        //   return newErrors;
        // });
        setEmailError((prevErrors) => {
          const { [`${itemId}-${index}`]: _, ...restErrors } = prevErrors; // Remove the error key for this email
          return restErrors;
        });
      }
    }
  };

  const [errmsg, setErrMsg] = useState("");
  const handlePayment = async (e) => {
    e.preventDefault();
    setErrMsg("");
    const sdata = selectedEvents.some((e) => e.selected);
    let mamount = 0;
    if (sdata) {
      if (selectedEvents.length > 0) {
        const selectedData = selectedEvents.filter((e) => e.selected);
        let allValid = true;
        let allSele = true;
        for (let i = 0; i < selectedData.length; i++) {
          const persons = selectedData[i].perdet;
          mamount +=
            parseInt(selectedData[i].total) +
            parseInt(selectedData[i].totalgst);

          if (persons.length > 0) {
            for (let j = 0; j < persons.length; j++) {
              const person = persons[j];
              if (
                !person.name ||
                !person.dob ||
                !person.gender ||
                !person.tshirt ||
                !person.phone ||
                !person.email
              ) {
                allValid = false;
                break;
              }
            }
          } else {
            allSele = false;
            setErrMsg("Please Add Person Details");
          }

          if (Object.keys(emailError).length === 0) {
            // paynow(selectedData[i].id,mamount);
            console.log(selectedData);
            for (let j = 0; j < persons.length; j++) {
              let samt = 0;
              samt =
                parseInt(selectedData[i].total) +
                parseInt(selectedData[i].totalgst);
              const tamt = 0;
              if (samt > 0) {
                tamt = samt / persons.length;
              }
              // const data = new FormData();
              // data.append("Name", persons[j].name);
              // data.append("Email", persons[j].email);
              // data.append("DOB", persons[j].dob);
              // data.append("Gender", persons[j].gender);
              // data.append("AgeCategory", selectedData[i].agecategory);
              // data.append("RegistrationType", "Individual");
              // data.append("Address", "Coimbatore");
              // data.append("District", "Coimbatore");
              // data.append("State", "Tamil Nadu");
              // data.append("EventRange", selectedData[i].kmsName);
              // data.append("amount", tamt);
              // data.append("OrderID", "no");
              // data.append("PaymentID", "no");
              // data.append("PaymentStatus", 0);
              // data.append("PaymentGateway", "Free");
              // data.append("EntryStatus", 1);
              // data.append("event", id);
              // data.append("Mobile", persons[j].phone);
              // data.append("TShirtSize", persons[j].tshirt);
              // data.append("file", null);
              // data.append("bibno", 0);
              // data.append("EventRange2", null);
              // data.append("AadharNo", null);
              // data.append("Represent", null);
              // data.append("Pincode", null);
              // data.append("FatherName", null);
              // for (let pair of data.entries()) {
              //   console.log(pair[0] + ": " + pair[1]);
              // }
              try {
                // const data = {
                //   kms: selectedData[i].kmsName,
                //   persons: selectedData[i].numberOfPersons,
                //   total: selectedData[i].total,
                //   gst: selectedData[i].totalgst,
                // };
                const payload = {
                  data: {
                    Name: persons[j].name,
                    Email: persons[j].email,
                    DOB: persons[j].dob,
                    Gender: persons[j].gender,
                    AgeCategory: selectedData[i].agecategory,
                    RegistrationType: "Individual",
                    Address: "Coimbatore",
                    District: "Coimbatore",
                    State: "Tamil Nadu",
                    EventRange: selectedData[i].kmsName,
                    amount: tamt + "",
                    OrderID: "no",
                    PaymentID: "no",
                    PaymentStatus: "0",
                    PaymentGateway: "Free",
                    EntryStatus: "1",
                    event: id,
                    Mobile: persons[j].phone,
                    TShirtSize: persons[j].tshirt,
                    userid: localStorage.getItem("UID"),
                  },
                };

                await axios.post(`${baseUrl}/evententries`, payload, {
                  headers: {
                    "Content-Type": "application/json",
                  },
                });
                navigate("/success/" + id);
                setErrMsg("");
              } catch (error) {
                console.error(
                  "An error occurred:",
                  error.response?.data || error.message
                );
              }
            }
          } else {
            setErrMsg("Please enter a valid email address.");
          }
        }

        if (!allSele) {
          setErrMsg("Please Add Person Details.");
        } else if (!allValid) {
          setErrMsg(
            "Please ensure all person details are filled for each entry."
          );
        }
        // if (allSele && allValid) {
        //   // Once data is saved, initiate payment
        //   const orderId = "ORDER_" + Math.ceil(Math.random() * 10000);
        //   const amount = mamount;
        //   const userId = localStorage.getItem("UID");
        //   const billingDetails = {
        //     name: "Yuvarekhaa",
        //     address: "Sathyamangalam",
        //     city: "Coimbatore",
        //     state: "Tamil Nadu",
        //     zip: "638402",
        //     country: "India",
        //     phone: "9965013232",
        //     email: "yuvarekhaa@sportingindia.tech",
        //   };
        //   await initiatePayment(orderId, amount, userId, billingDetails);
        // }
      }
    } else {
      setErrMsg("Please select atleast one category");
    }
  };

  const [accessCode, setAccessCode] = useState("");
  const [encryptedData, setEncryptedData] = useState("");

  const handleCheckOut = async (e) => {
    if (localStorage.getItem("UID") !== null) {
      const orderId = "ORDER_" + Math.ceil(Math.random() * 10000);
      const amount = 1000;
      const userId = localStorage.getItem("UID");
      const billingDetails = {
        name: "Yuvarekhaa",
        address: "Sathyamangalam",
        city: "Coimbatore",
        state: "Tamil Nadu",
        zip: "638402",
        country: "India",
        phone: "9965013232",
        email: "yuvarekhaa@sportingindia.tech",
      };
      await initiatePayment(orderId, amount, userId, billingDetails);
    }
  };

  const initiatePayment = async (orderId, amount, userId, billingDetails) => {
    try {
      // Call the initiatePayment API to get access code and encrypted data
      const response = await axios.post(`${baseUrl}/orders/initiatePayment`, {
        order_id: orderId,
        amount: "1",
        user_id: userId,
        billing_details: billingDetails,
      });
      const { accessCode, encryptedData } = response.data;
      setAccessCode(accessCode);
      setEncryptedData(encryptedData);
      setTimeout(() => {
        document.getElementById("ccavenuePaymentForm").submit();
      }, 0);
    } catch (error) {
      console.error("Error initiating payment:", error);
      setErrMsg("Failed to initiate payment. Please try again.");
    }
  };

  async function paynow(ordid, tamt) {
    const options = {
      key: "rzp_test_8WQW8hdjE9u6hQ",
      order_id: "order_ABC123456",
      amount: tamt,
      currency: "INR",
      name: "SportingIndia",
      description: "Marathon Registration",
      image: require("../assets/images/logo/logo.png"),
      handler: function (response) {
        // const temp = new FormData();
        // temp.append("razorpay_payment_id", response.razorpay_payment_id);
        // temp.append("totalAmount", tamt);
        // temp.append("current_meet_id", id);
        // temp.append("order_id", ordid);
      },
      prefill: {
        email: "",
        phone_number: "",
      },
    };
    const paymentObj = new window.Razorpay(options);
    paymentObj.open();
  }

  return (
    <div>
      {loading && (
        <div className="preloader">
          <div className="preloader-inner">
            <div className="preloader-icon">
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      )}
      {!loading && (
        <>
          <EventSub />
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-sm-10 col-md-6 mb-50"></div>
              {eventdet && (
                <>
                  {revent && (
                    <div className="col-lg-9 mb-50">
                      <div className="checkout-widget checkout-contact">
                        <h5 className="title">Book your category</h5>
                        <div className="ticket--area row justify-content-center">
                          {revent.map((event) => {
                            const isSelected = selectedEvents.some(
                              (e) => e.id === event.id && e.selected
                            );
                            return (
                              <div
                                className="col-sm-6 col-md-3"
                                key={event.id}
                                onClick={() => handleAdd(event.id)}
                              >
                                <div
                                  className={`sports-ticket style-two ${
                                    isSelected ? "active" : ""
                                  }`}
                                >
                                  <span className="cate">
                                    {
                                      event.attributes.event_range.data
                                        .attributes.Name
                                    }
                                  </span>

                                  {event.attributes.amount === 0 ||
                                  event.attributes.amount === null ? (
                                    <h2 className="ticket-title">
                                      <span>Free</span>
                                    </h2>
                                  ) : (
                                    <h2 className="ticket-title">
                                      <sup>₹ </sup>
                                      {event.attributes.amount}
                                      {event.attributes.GST > 0 &&
                                        event.attributes.GST !== null && (
                                          <span>
                                            + GST {event.attributes.GST} %
                                          </span>
                                        )}
                                    </h2>
                                  )}
                                  <a className="t-button">
                                    <i
                                      className={
                                        isSelected
                                          ? "fas fa-check"
                                          : "fas fa-plus"
                                      }
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        {selectedEvents.map((item) => {
                          if (item.selected) {
                            return (
                              <div className="row mb-30-none mt-2">
                                <div
                                  className="col-md-8 col-xl-7"
                                  key={item.id}
                                >
                                  <form className="cart-button event-cart">
                                    <span className="d-inline-block">
                                      Number of Persons for {item.kmsName} :
                                    </span>
                                    <div className="cart-plus-minus">
                                      <div
                                        className="dec qtybutton"
                                        onClick={() => AddDec(item.id)}
                                      >
                                        -
                                      </div>
                                      <input
                                        className="cart-plus-minus-box"
                                        type="text"
                                        name="qtybutton"
                                        value={item.numberOfPersons}
                                      />
                                      <div
                                        className="inc qtybutton"
                                        onClick={() => AddInc(item.id)}
                                      >
                                        +
                                      </div>
                                    </div>
                                  </form>
                                </div>
                                <div className="col-md-4 col-xl-5">
                                  <a
                                    className="btn btn-danger"
                                    onClick={() => handleAdd(item.id)}
                                  >
                                    Remove
                                  </a>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>

                      <div className="checkout-widget checkout-card mb-0">
                        <form className="">
                          {selectedEvents.map((item) => {
                            if (item.selected) {
                              return (
                                <div key={item.id}>
                                  <h5 className="title">
                                    Person Details for {item.kmsName}
                                  </h5>
                                  {item.perdet.map((person, index) => (
                                    <div
                                      key={index}
                                      className="payment-card-form"
                                    >
                                      <div className="w-100">
                                        <h6 className="subtitle">
                                          Person {index + 1} :
                                        </h6>
                                      </div>
                                      <div className="form-group">
                                        <label
                                          htmlFor={`name-${item.id}-${index}`}
                                        >
                                          Person Name
                                        </label>
                                        <input
                                          type="text"
                                          id={`name-${item.id}-${index}`}
                                          placeholder="Person Name"
                                          value={person.name}
                                          onChange={(e) => {
                                            const updatedPerDet = [
                                              ...item.perdet,
                                            ];
                                            updatedPerDet[index].name =
                                              e.target.value;
                                            setSelectedEvents((prevEvents) =>
                                              prevEvents.map((event) =>
                                                event.id === item.id
                                                  ? {
                                                      ...event,
                                                      perdet: updatedPerDet,
                                                    }
                                                  : event
                                              )
                                            );
                                          }}
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label
                                          htmlFor={`dob-${item.id}-${index}`}
                                        >
                                          Date Of Birth
                                        </label>
                                        <input
                                          type="date"
                                          id={`dob-${item.id}-${index}`}
                                          placeholder="Date of Birth"
                                          min={item.startDate}
                                          max={item.endDate}
                                          value={person.dob} // Add value here if you're tracking dob
                                          onChange={(e) => {
                                            const updatedPerDet = [
                                              ...item.perdet,
                                            ];
                                            updatedPerDet[index].dob =
                                              e.target.value;
                                            setSelectedEvents((prevEvents) =>
                                              prevEvents.map((event) =>
                                                event.id === item.id
                                                  ? {
                                                      ...event,
                                                      perdet: updatedPerDet,
                                                    }
                                                  : event
                                              )
                                            );
                                          }}
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label
                                          htmlFor={`gender-${item.id}-${index}`}
                                        >
                                          Gender
                                        </label>
                                        <select
                                          id={`gender-${item.id}-${index}`}
                                          value={person.gender} // Add value here if you're tracking gender
                                          onChange={(e) => {
                                            const updatedPerDet = [
                                              ...item.perdet,
                                            ];
                                            updatedPerDet[index].gender =
                                              e.target.value;
                                            setSelectedEvents((prevEvents) =>
                                              prevEvents.map((event) =>
                                                event.id === item.id
                                                  ? {
                                                      ...event,
                                                      perdet: updatedPerDet,
                                                    }
                                                  : event
                                              )
                                            );
                                          }}
                                        >
                                          <option value="" disabled>
                                            -- Select Gender --
                                          </option>
                                          <option value="Male">Male</option>
                                          <option value="Female">Female</option>
                                          <option value="Transgender">
                                            Transgender
                                          </option>
                                        </select>
                                      </div>
                                      <div className="form-group">
                                        <label
                                          htmlFor={`tshirt-${item.id}-${index}`}
                                        >
                                          T-Shirt Size
                                        </label>
                                        <select
                                          id={`tshirt-${item.id}-${index}`}
                                          value={person.tshirt} // Add value here if you're tracking tshirt size
                                          onChange={(e) => {
                                            const updatedPerDet = [
                                              ...item.perdet,
                                            ];
                                            updatedPerDet[index].tshirt =
                                              e.target.value;
                                            setSelectedEvents((prevEvents) =>
                                              prevEvents.map((event) =>
                                                event.id === item.id
                                                  ? {
                                                      ...event,
                                                      perdet: updatedPerDet,
                                                    }
                                                  : event
                                              )
                                            );
                                          }}
                                        >
                                          <option value="" disabled>
                                            -- Select Size --
                                          </option>
                                          <option value="S">S</option>
                                          <option value="M">M</option>
                                          <option value="L">L</option>
                                          <option value="XL">XL</option>
                                          <option value="XXL">XXL</option>
                                          <option value="XXXL">XXXL</option>
                                        </select>
                                      </div>
                                      <div className="form-group">
                                        <label
                                          htmlFor={`mobile-${item.id}-${index}`}
                                        >
                                          Mobile Number
                                        </label>
                                        <input
                                          type="text"
                                          id={`mobile-${item.id}-${index}`}
                                          placeholder="Mobile Number"
                                          value={person.phone}
                                          onChange={(e) => {
                                            const updatedPhone =
                                              e.target.value.replace(
                                                /[^0-9]/g,
                                                ""
                                              );
                                            const updatedPerDet = [
                                              ...item.perdet,
                                            ];
                                            updatedPerDet[index].phone =
                                              updatedPhone;
                                            setSelectedEvents((prevEvents) =>
                                              prevEvents.map((event) =>
                                                event.id === item.id
                                                  ? {
                                                      ...event,
                                                      perdet: updatedPerDet,
                                                    }
                                                  : event
                                              )
                                            );
                                          }}
                                          pattern="[0-9]*"
                                          maxLength="15"
                                          title="Please enter a valid mobile number"
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label
                                          htmlFor={`email-${item.id}-${index}`}
                                        >
                                          Email ID
                                        </label>
                                        <input
                                          type="email"
                                          id={`email-${item.id}-${index}`}
                                          placeholder="Email ID"
                                          value={person.email}
                                          onChange={(e) => {
                                            const updatedPerDet = [
                                              ...item.perdet,
                                            ];
                                            updatedPerDet[index].email =
                                              e.target.value;
                                            setSelectedEvents((prevEvents) =>
                                              prevEvents.map((event) =>
                                                event.id === item.id
                                                  ? {
                                                      ...event,
                                                      perdet: updatedPerDet,
                                                    }
                                                  : event
                                              )
                                            );
                                          }}
                                          onBlur={(e) => {
                                            validateEmail(
                                              e.target.value,
                                              item.id,
                                              index
                                            );
                                          }}
                                        />
                                        {emailError[`${item.id}-${index}`] && (
                                          <span className="text-danger">
                                            {emailError[`${item.id}-${index}`]}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              );
                            }
                            return null;
                          })}

                          <div className="summarysection">
                            <div className="wrap">
                              <div className="cart-header cf">
                                <strong>Summary</strong>
                              </div>
                              <div className="cart-table table-responsive">
                                <table className="summary-tablee">
                                  <thead>
                                    <tr>
                                      <td>S.No</td>
                                      <td>Events</td>
                                      <td>Persons</td>
                                      <td>Rate</td>
                                      <td>GST</td>
                                      <td>Total</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {selectedEvents.map((item, index) => {
                                      if (item.selected) {
                                        const sno =
                                          selectedEvents
                                            .filter((event) => event.selected)
                                            .indexOf(item) + 1;
                                        return (
                                          <tr key={item.id}>
                                            <td>{sno}</td>
                                            <td>{item.kmsName}</td>
                                            <td>{item.numberOfPersons}</td>
                                            <td>
                                              {item.price !== 0 &&
                                              item.price !== null
                                                ? "₹" + item.price
                                                : "Free"}
                                            </td>
                                            <td>
                                              {item.gst !== 0 &&
                                              item.gst !== null
                                                ? `${item.gst} %`
                                                : ""}
                                            </td>
                                            <td>
                                              {item.price !== 0 &&
                                              item.price !== null
                                                ? "₹" +
                                                  item.price *
                                                    item.numberOfPersons
                                                : "Free"}
                                              {/* ₹{" "}
                                              {item.price *
                                                item.numberOfPersons} */}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    })}
                                  </tbody>
                                </table>
                              </div>
                              <div className="sub-table cf">
                                <div className="summary-block">
                                  <div className="sb-promo">
                                    <input
                                      type="text"
                                      placeholder="Enter Promo Code"
                                    />
                                    <span className="btn">Apply</span>
                                  </div>
                                  {parseInt(subtot) !== 0 &&
                                    parseInt(subtot) !== null && (
                                      <ul>
                                        <li className="subtotal">
                                          <span className="sb-label">
                                            Subtotal
                                          </span>
                                          <span className="sb-value">
                                            ₹ {subtot}
                                          </span>
                                        </li>
                                        <li className="tax">
                                          <span className="sb-label">
                                            SGST (9%)
                                          </span>
                                          <span className="sb-value">
                                            ₹ {(subgst / 2).toFixed(2)}
                                          </span>
                                        </li>
                                        <li className="tax">
                                          <span className="sb-label">
                                            CGST (9%)
                                          </span>
                                          <span className="sb-value">
                                            ₹ {(subgst / 2).toFixed(2)}
                                          </span>
                                        </li>
                                        <li className="tax-calculate">
                                          <input
                                            type="text"
                                            value="06484"
                                            className="tax"
                                          />
                                          <span className="btn">Calculate</span>
                                        </li>
                                        <li className="grand-total">
                                          <span className="sb-label">
                                            Total
                                          </span>
                                          <span className="sb-value">
                                            ₹{" "}
                                            {Math.round(
                                              parseFloat(subtot) +
                                                parseFloat(subgst)
                                            ).toFixed(2)}
                                          </span>
                                        </li>
                                      </ul>
                                    )}
                                </div>
                                <div className="copy-block">
                                  <p className="customer-care">
                                    Call us M-F 8:00 am to 9:00 pm IST
                                    <br />
                                    (+91)-111-111-1111 or <a>
                                      contact us
                                    </a>. <br />
                                  </p>
                                </div>
                              </div>
                              {errmsg && (
                                <div className="alert alert-danger">
                                  {errmsg}
                                </div>
                              )}
                              {parseInt(subtot) !== 0 &&
                                parseInt(subtot) !== null && (
                                  <div className="cart-footer cf mt-2">
                                    {/* handlePayment handleCheckOut*/}

                                    <span
                                      className="btn"
                                      onClick={handlePayment}
                                    >
                                      Make Payment
                                    </span>
                                    <span className="cont-shopping">
                                      By Clicking "Make Payment" you agree to
                                      the <a>terms and conditions</a>
                                    </span>

                                    {accessCode && encryptedData ? (
                                      <form
                                        id="ccavenuePaymentForm"
                                        method="POST"
                                        action="https://secure.ccavenue.com/transaction/transaction.do"
                                      >
                                        {/* Hidden inputs for access code and encrypted data */}
                                        <input
                                          type="hidden"
                                          name="encRequest"
                                          value={encryptedData}
                                        />
                                        <input
                                          type="hidden"
                                          name="access_code"
                                          value={accessCode}
                                        />

                                        {/* Button to submit the payment form */}
                                        <button
                                          type="submit"
                                          id="paymentButton"
                                          style={{ display: "none" }}
                                        >
                                          Proceed to Pay
                                        </button>
                                      </form>
                                    ) : (
                                      <p style={{ display: "none" }}>
                                        Loading payment details...
                                      </p>
                                    )}
                                  </div>
                                )}

                              {(parseInt(subtot) === 0 ||
                                parseInt(subtot) === null) && (
                                <div className="cart-footer cf mt-2">
                                  <span className="btn" onClick={handlePayment}>
                                    Submit
                                  </span>
                                  <span className="cont-shopping">
                                    By Clicking "Submit" you agree to the{" "}
                                    <a>terms and conditions</a>
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EventBooking;

import React, { useState } from "react";
import EventSub from "../components/EventSub";
import { useParams } from "react-router-dom";

export const SuccessPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  return (
    <div>
      {loading && (
        <div className="preloader">
          <div className="preloader-inner">
            <div className="preloader-icon">
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      )}
      {!loading && (
        <>
          <EventSub />
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-sm-10 col-md-6 mb-50"></div>
              <div className="col-lg-9 mb-50">
                <div className="checkout-widget checkout-success">
                  <h5 className="title text-success text-center">Thank you for registering the event</h5>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

import React, { useEffect, useState } from "react";
import EventSub from "../components/EventSub";
import { useParams } from "react-router-dom";
import baseUrl from "../Api";
import moment from "moment";

export const MyBookingList = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [eventdet, setEventDet] = useState([]);

  useEffect(() => {
    fetchEvent();
  }, []);

  const fetchEvent = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        baseUrl +
          `/evententries?filters[event][id][$eq]=` +
          id +
          `&filters[userid][$eq]=` +
          localStorage.getItem("UID") +
          `&populate=*`
      );
      const data = await response.json();

      if (data.data !== null) {
        setEventDet(data.data);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <div>
      {loading && (
        <div className="preloader">
          <div className="preloader-inner">
            <div className="preloader-icon">
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      )}
      {!loading && (
        <>
          <EventSub />
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-sm-10 col-md-6 mb-50"></div>
              <div className="col-lg-9 mb-50">
                <div className="checkout-widget checkout-success">
                  <h5 className="title text-success text-center">Summary</h5>
                </div>
              </div>
              <div className="col-lg-12 mb-50">
                <div className="cart-table table-responsive">
                  <table className="summary-tablee">
                    <thead>
                      <tr>
                        <td>S.No</td>
                        <td>Name</td>
                        <td>Events</td>
                        <td>Category</td>
                        <td>Gender</td>
                        <td>Date of Birth</td>
                        <td>T-Shirt Size</td>
                        <td>Mobile</td>
                        <td>Email</td>
                        <td>Total</td>
                      </tr>
                    </thead>
                    <tbody>
                      {eventdet.map((data, i) => {
                        return (
                          <tr key={i}>
                            <td>{i+1}</td>
                            <td>{data.attributes.Name}</td>
                            <td>{data.attributes.EventRange}</td>
                            <td>{data.attributes.AgeCategory}</td>
                            <td>{data.attributes.Gender}</td>
                            <td>{moment(data.attributes.DOB).format('DD-MM-YYYY')}</td>
                            <td>{data.attributes.TShirtSize}</td>
                            <td>{data.attributes.Mobile}</td>
                            <td>{data.attributes.Email}</td>
                            <td>{data.attributes.amount == 0 ? "Free" : data.attributes.amount}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import baseUrl from "../Api";

export const Signup = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [pswd, setPswd] = useState("");
  const [name, setName] = useState("");
  const [mobno, setMobNo] = useState("");
  const [addr, setAddr] = useState("");
  const [rstate, setRState] = useState("");
  const [slist, setSList] = useState([]);
  const [dist, setDist] = useState("");
  const [city, setCity] = useState("");
  const [pin, setPin] = useState("");

  const navigate = useNavigate();
  const [error, setError] = useState("");

  const submitlogin = async (e) => {
    e.preventDefault();
    localStorage.clear();
    try {
      const response = await axios.post(`${baseUrl}/auth/local/register`, {
        username: username,
        email: email,
        password: pswd,
        name: name,
        mobile: mobno,
        address: addr,
        state: rstate,
        city: city,
        pincode: pin,
      });
      if (response.data !== null) {
        localStorage.setItem("Token", response.data.jwt);
        localStorage.setItem("UID", response.data.user.id);
      }
      navigate("/");
    } catch (error) {
      console.error("An error occurred:", error.response);
      setError(error.response.data.error.message);
    }
  };

  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setMobNo(e.target.value);
    }
  };

  const handlePin = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setPin(e.target.value);
    }
  };

  useEffect(() => {
    const fetchState = async () => {
      try {
        const response = await axios.get(baseUrl + `/states`);
        if (response.data.data !== null) {
          setSList(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching :", error);
      }
    };
    fetchState();
  }, []);

  return (
    <section class="account-section bg_img">
      <div class="container">
        <div class="padding-top padding-bottom">
          <div class="account-area">
            <div class="section-header-3">
              <h2 class="title">Welcome, Register Now!</h2>
            </div>
            <form class="account-form">
              <div class="form-group">
                <label for="name">
                  Name<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Your Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div class="form-group">
                <label for="username">
                  Username<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Your Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>

              <div class="form-group">
                <label for="email2">
                  Email<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div class="form-group">
                <label for="pass3">
                  Password<span>*</span>
                </label>
                <input
                  type="password"
                  placeholder="Password"
                  value={pswd}
                  onChange={(e) => setPswd(e.target.value)}
                  id="pass3"
                  required
                />
              </div>

              <div class="form-group">
                <label for="mobile">
                  Mobile Number<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Your Mobile Number"
                  value={mobno}
                  maxLength={10}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="form-group">
                <label for="address">
                  Address<span>*</span>
                </label>
                <textarea
                  rows="3"
                  placeholder="Enter Your Address"
                  value={addr}
                  onChange={(e) => setAddr(e.target.value)}
                  required
                />
              </div>

              <div class="form-group">
                <label for="state">
                  State<span>*</span>
                </label>
                <select
                  value={rstate}
                  onChange={(e) => setRState(e.target.value)}
                >
                  <option value="" defaultValue disabled>
                    --- Select State ---
                  </option>
                  {slist.map((data, i) => {
                    return (
                      <option value={data.attributes.state} key={i}>
                        {data.attributes.state}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* <div class="form-group">
                <label for="district">
                  District<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Your District"
                  value={dist}
                  onChange={(e) => setDist(e.target.value)}
                  required
                />
              </div> */}
              <div class="form-group">
                <label for="district">
                  City<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Your City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                />
              </div>
              <div class="form-group">
                <label for="pincode">
                  Pincode<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Your Pincode"
                  value={pin}
                  maxLength={6}
                  onChange={handlePin}
                  required
                />
              </div>
              {error && <div className="alert alert-danger mt-4">{error}</div>}
              <div class="form-group text-center">
                <a to="" className="custom-button w-100" onClick={submitlogin}>
                  {" "}
                  Register
                </a>
              </div>
            </form>
            <div class="option">
              Already have an account?{" "}
              <a
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/login")}
              >
                Login
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

import React, { useEffect, useState } from "react";
import baseUrl from "../Api";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import Banner from "../components/Banner";

const searchBg = {
  backgroundImage: "url(images/ticket/ticket-bg01.jpg)",
};

export const CategoryEvents = () => {
  const { id } = useParams();
  const [categories, setCategories] = useState([]);
  const [elist, setEventList] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [cate, setCate] = useState("");
  const location = useLocation();

  const [urllink, setUrlLink] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [fdate, setFDate] = useState("");

  useEffect(() => {
    const url = location.pathname.split("/")[1];
    setUrlLink(url);
    if (url === "categorywise") {
      setLoading(true);
      const fetchCategory = async () => {
        try {
          const response = await axios.get(baseUrl + `/eventcategories/${id}`);
          if (response.data.data !== null) {
            setCate(response.data.data);
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error("Error fetching category:", error);
        }
      };
      fetchCategory();
    }

    fetchCate();
    fetchEvent(url);
  }, [location.pathname, id]);
  const [checkedItems, setCheckedItems] = useState({});
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const clearFilters = () => {
    setCheckedItems({});
    fetchCate();
  };

  const fetchCate = async () => {
    setCategories([]);
    try {
      const response = await fetch(baseUrl + "/eventcategories");
      const data = await response.json();
      if (data.data !== null) {
        setCategories(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEvent = async (ltype) => {
    if (ltype === "categorywise") {
      setLoading(true);
      setEventList([]);
      setFilteredEvents([]);
      try {
        const response = await fetch(
          baseUrl + `/events?filters[event_category][id][$eq]=${id}&populate=*`
        );
        const data = await response.json();
        if (data && data.data !== null) {
          setEventList(data.data);
          filterEvents(data.data, searchQuery, fdate);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      setLoading(true);
      setEventList([]);
      setFilteredEvents([]);

      try {
        const response = await fetch(baseUrl + "/events?populate=*");
        const data = await response.json();
        if (data && data.data !== null) {
          setEventList(data.data);
          filterEvents(data.data, searchQuery, fdate);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    filterEvents(elist, query); // Filter based on the input value
  };

  const filterEvents = (events, query, date) => {
    let filtered = events;

    if (query) {
      filtered = filtered.filter((event) => {
        const eventName = event.attributes.Name.toLowerCase();
        const eventCategory =
          event.attributes.event_category?.data?.attributes?.Name.toLowerCase();
        return (
          eventName.includes(query.toLowerCase()) ||
          eventCategory.includes(query.toLowerCase())
        );
      });
    }

    if (date) {
      const selectedDate = new Date(date);
      filtered = filtered.filter((event) => {
        const eventStartDate = new Date(event.attributes.startdate);
        return eventStartDate >= selectedDate;
      });
    }

    setFilteredEvents(filtered);
  };

  const [loading, setLoading] = useState(false);
  return (
    <div>
      {loading && (
        <div className="preloader">
          <div className="preloader-inner">
            <div className="preloader-icon">
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      )}
      {!loading && (
        <>
        <Banner />
           <section className="search-ticket-section padding-top pt-lg-0">
            <div className="container">
              <div className="search-tab bg_img" style={searchBg}>
                <div className="row align-items-center mb--20">
                  <div className="col-lg-6 mb-0">
                    <div className="search-ticket-header">
                      <h6 className="category">Welcome to Sportingindia! </h6>
                      <h3 className="title">what are you looking for</h3>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-10">
                    <ul className="tab-menu ticket-tab-menu">
                      <li className="active">
                        <div className="tab-thumb">
                          <img
                            src={require("../assets/images/ticket/running.png")}
                            alt="ticket"
                          />
                        </div>
                        <span>Sports</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="tab-area">
                  <div className="tab-item active">
                    <form className="ticket-search-form">
                      <div className="form-group large me-3">
                        <input
                          type="text"
                          placeholder="Search for Sports"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                        <button type="submit">
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                      {/* <div className="form-group">
                                <div className="thumb">
                                    <img src={require('../assets/images/ticket/city.png')} alt="ticket" />
                                </div>
                                <span className="type">city</span>
                                <select className="nice-select select-bar">
                                    <option value="london">London</option>
                                    <option value="dhaka">dhaka</option>
                                    <option value="rosario">rosario</option>
                                    <option value="madrid">madrid</option>
                                    <option value="koltaka">kolkata</option>
                                    <option value="rome">rome</option>
                                    <option value="khoksa">khoksa</option>
                                </select>
                            </div> */}
                      <div className="form-group">
                        <div className="thumb">
                          <img
                            src={require("../assets/images/ticket/date.png")}
                            alt="ticket"
                          />
                        </div>
                        <span className="type">date</span>
                        <input
                          type="date"
                          className="nice-select"
                          onChange={(e) => {
                            setFDate(e.target.value); // Set the selected date
                            filterEvents(elist, searchQuery, e.target.value); // Filter based on the selected date
                          }}
                          value={fdate}
                        />
                        {/* <select className="nice-select select-bar">
                                    <option value="26-12-19">23/10/2020</option>
                                    <option value="26-12-19">24/10/2020</option>
                                    <option value="26-12-19">25/10/2020</option>
                                    <option value="26-12-19">26/10/2020</option>
                                </select> */}
                      </div>
                      {/* <div className="form-group">
                                <div className="thumb">
                                    <img src={require('../assets/images/ticket/cinema.png')} alt="ticket" />
                                </div>
                                <span className="type">sports</span>
                                <select className="nice-select select-bar">
                                    <option value="football">Football</option>
                                    <option value="cricket">Cricket</option>
                                    <option value="cabadi">Marathon</option>
                                    <option value="madrid">Kabaddi</option>
                                    <option value="gadon">Runing</option>
                                    <option value="rome">Chess</option>
                                    <option value="khoksa">Cycling</option>
                                </select>
                            </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="movie-section padding-top padding-bottom bg-two">
            <div className="container">
              <div className="row flex-wrap-reverse justify-content-center">
                <div className="col-lg-3 col-sm-10  mt-50 mt-lg-0">
                  <div className="widget-1 widget-check">
                    {/* <div className="widget-header">
      <h5 className="m-title">Filter By</h5>{" "}
      <a
        className="clear-check"
        onClick={clearFilters}
        style={{ cursor: "pointer" }}
      >
        Clear All
      </a>
    </div> */}
                    <div className="widget-1-body">
                      <h6 className="subtitle">categories</h6>
                      <div className="check-area">
                        {categories.map((category) => {
                          const { id, attributes } = category;
                          return (
                            <div className="form-group" key={id}>
                              <Link to={`/categorywise/${id}`}>
                                <span
                                  style={{
                                    cursor: "pointer",
                                    fontWeight: "600",
                                  }}
                                >
                                  {attributes.Name}
                                </span>
                              </Link>
                              {/* <input
                type="checkbox"
                name={attributes.Name}
                id={id}
                checked={checkedItems[attributes.Name] || false}
                onChange={handleCheckboxChange}
              /> 
              <label htmlFor={id}>{attributes.Name}</label>*/}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="widget-1 widget-banner">
                    <div className="widget-1-body">
                      <a>
                        <img
                          src={require("../assets/images/sidebar/banner/banner02.jpg")}
                          alt="banner"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="article-section">
                    <div className="section-header-1">
                      <h2 className="title">
                        {cate !== "" ? cate.attributes.Name + " Category " : ""}
                        Sports in Coimbatore
                      </h2>
                      {/* <a className="view-all">View All</a> */}
                    </div>
                    <div className="row mb-30-none">
                      {filteredEvents.length === 0 && (
                        <h5 className="text-center">
                          No Events are available for this category
                        </h5>
                      )}
                      {filteredEvents.map((event) => {
                        const { id, attributes } = event;
                        const eventDate = new Date(attributes.startdate);
                        const day = eventDate.getUTCDate();
                        const options = { month: "short" };
                        const month = new Intl.DateTimeFormat(
                          "en-US",
                          options
                        ).format(eventDate);
                        const currentDate = new Date();
                        const isPastEvent = eventDate < currentDate;

                        const eventRanges =
                          attributes.event_range_amounts?.data || [];
                        const amounts = eventRanges
                          .map((range) => range.attributes.amount)
                          .filter((amount) => amount !== null);
                        const minAmount =
                          amounts.length > 0 ? Math.min(...amounts) : null;
                        const maxAmount =
                          amounts.length > 0 ? Math.max(...amounts) : null;

                        return (
                          <div className="col-sm-6 col-lg-4" key={id}>
                            <div className="sports-grid">
                              <div className="movie-thumb c-thumb">
                                <Link to={`/events/${id}`}>
                                  {attributes.Thumbnail.data !== null && (
                                    <img
                                      src={`https://strapi.sportingindia.com${
                                        attributes.Thumbnail?.data?.attributes
                                          ?.url ||
                                        require("../assets/images/sporting.jpeg")
                                      }`}
                                      alt={attributes.Name}
                                      width={"255px"}
                                      height={"357px"}
                                    />
                                  )}
                                  {attributes.Thumbnail.data === null && (
                                    <img
                                      src={require("../assets/images/sporting.jpeg")}
                                      alt={attributes.Name}
                                      width={"255px"}
                                      height={"357px"}
                                    />
                                  )}
                                </Link>
                                <div className="event-date">
                                  <h6 className="date-title">{day}</h6>
                                  <span>{month}</span>
                                </div>
                              </div>
                              <div className="movie-content bg-one">
                                <span className="cate">
                                  {
                                    attributes.event_category?.data?.attributes
                                      ?.Name
                                  }
                                </span>
                                <h5 className="title m-0">
                                  <Link to={`/events/${id}`}>
                                    {attributes.Name}
                                  </Link>
                                </h5>
                                <div className="movie-rating-percent">
                                  <span>{attributes.location}</span>
                                </div>
                                <div className="reisprice">
                                  {isPastEvent ? (
                                    <span style={{ color: "red" }}>
                                      Event Closed
                                    </span>
                                  ) : (
                                    <>
                                      Registration Fees:{" "}
                                      <span>
                                        {amounts.length === 1
                                          ? `₹ ${minAmount}`
                                          : amounts.length > 1
                                          ? `₹ ${minAmount} - ₹ ${maxAmount}`
                                          : "-"}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {/* <div className="pagination-area text-center">
      <a>
        <i className="fas fa-angle-double-left"></i>
        <span>Prev</span>
      </a>
      <a className="active">1</a>
      <a>2</a>
      <a>3</a>
      <a>4</a>
      <a>5</a>
      <a>
        <span>Next</span>
        <i className="fas fa-angle-double-right"></i>
      </a>
    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

import React from 'react';


const Banner = () => {
    return (
        <section className="banner-section">
            <div className="banner-bg bg_img bg-fixed" style={{backgroundImage: `url(${require('../assets/images/banner/banner01.jpg')})`}}></div>
            {/* <div className="container">
                <div className="banner-content">
                    <h1 className="title  cd-headline clip"><span className="d-block">Confirm your</span> Participation for <span className="color-theme cd-words-wrapper p-0 m-0">
                            <b className="is-visible">Running</b>
                            <b>Marathon</b>
                            <b>Kabaddi</b>
                            <b>Cycling</b>
                        </span>
                    </h1>
                    <p>Your gateway to seamless sports event registration and unforgettable experiences!</p>
                </div>
            </div> */}
            
        </section>
    );
};

export default Banner;
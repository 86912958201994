import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import baseUrl from "../Api";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [pswd, setPswd] = useState("");
  const navigate=useNavigate();
  const [error, setError] = useState("");

  const submitlogin = async (e) => {
    e.preventDefault();
    localStorage.clear();
    try{
      const response = await axios.post(`${baseUrl}/auth/local`, {
        identifier: email,
        password: pswd,
      });
      if(response.data !== null){
        localStorage.setItem("Token", response.data.jwt);
        localStorage.setItem("UID", response.data.user.id);

      }
      navigate('/');
    }catch (error) {
      console.error('An error occurred:', error.response);
      setError(error.response.data.error.message);
    }
    
  };

  useEffect(()=>{
    if(localStorage.getItem("UID") !== null){
      navigate("/")
    }
  },[])
  return (
    <section class="account-section bg_img">
      <div class="container">
        <div class="padding-top padding-bottom">
          <div class="account-area">
            <div class="section-header-3">
              <h2 class="title">Member Login</h2>
            </div>
            <form class="account-form">
              <div class="form-group">
                <label for="email2">
                  Email<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="email2"
                  required
                />
              </div>
              <div class="form-group">
                <label for="pass3">
                  Password<span>*</span>
                </label>
                <input
                  type="password"
                  placeholder="Password"
                  value={pswd}
                  onChange={(e) => setPswd(e.target.value)}
                  id="pass3"
                  required
                />
              </div>
              <div class="form-group checkgroup">
                <input type="checkbox" id="bal2" required checked />
                <label for="bal2">remember</label>
                <a class="forget-pass">Forget Password</a>
              </div>
              {error && <div className="alert alert-danger mt-4">{error}</div>}
              <div class="form-group text-center">
                <a to="" className="custom-button w-100" onClick={submitlogin}>
                  {" "}
                  Login
                </a>
              </div>
            </form>
            <div class="option">
              Don't have an account? <a style={{ cursor: "pointer" }} onClick={() => navigate("/signup")}>sign up now</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

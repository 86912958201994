import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import bannerImage from "../assets/images/banner/banner09.jpg"; // Adjust the path as necessary
import baseUrl from "../Api";
import moment from "moment";

const detailsBg = {
  backgroundImage: `url(${bannerImage})`,
};

const EventMain = () => {
  const { id } = useParams();
  const [targetDate, setTargetDate] = useState("");
  const [eventdet, setEventDet] = useState("");
  const [revent, setREvent] = useState([]);

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  useEffect(() => {
    fetchEvent();
  }, [id]);

  const fetchEvent = async () => {
    // setEventList([]);
    setLoading(true);
    try {
      const response = await fetch(baseUrl + `/events/${id}?populate=*`);
      const data = await response.json();
      if (data.data !== null) {
        setEventDet(data.data);
        const startDate = new Date(data.data.attributes.startdate).getTime();
        setTargetDate(startDate);
        const eventRangeAmounts = data.data.attributes.event_range_amounts.data;

        if (eventRangeAmounts && eventRangeAmounts.length > 0) {
          const newREventData = [];
          for (let i = 0; i < eventRangeAmounts.length; i++) {
            const response = await fetch(
              `${baseUrl}/event-range-amounts/${eventRangeAmounts[i].id}?populate=*`
            );
            const data1 = await response.json();

            if (data1.data) {
              newREventData.push(data1.data); // Append new data to existing state
            }
          }
          setREvent((prevREvent) => [...prevREvent, ...newREventData]);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (targetDate) {
      const interval = setInterval(() => {
        const now = new Date().getTime();
        const distance = targetDate - now;

        // Calculate time left
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Update state
        setTimeLeft({ days, hours, minutes, seconds });

        // Check if countdown is finished
        if (distance < 0) {
          clearInterval(interval);
          setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        }
      }, 1000);

      // Cleanup function
      return () => clearInterval(interval);
    }
  }, [targetDate]); // Only run this effect when targetDate changes

  const [loading, setLoading] = useState(false);

  return (
    <div>
      {loading && (
        <div className="preloader">
          <div className="preloader-inner">
            <div className="preloader-icon">
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      )}
      {!loading && (
        <>
          {eventdet && (
            <>
              {(() => {
                const eventDate = new Date(eventdet.attributes.startdate);
                const currentDate = new Date();
                const isPastEvent = eventDate < currentDate;

                return (
                  <>
                    <section className="details-banner" style={detailsBg}>
                      <div className="container">
                        <div className="details-banner-wrapper">
                          <div className="details-banner-thumb">
                            {eventdet.attributes.Thumbnail.data !== null && (
                              <img
                                src={`https://strapi.sportingindia.com${
                                  eventdet.attributes.Thumbnail?.data
                                    ?.attributes?.url ||
                                  require("../assets/images/sporting.jpeg")
                                }`}
                                alt={eventdet.attributes.Name}
                                width={"255px"}
                                height={"357px"}
                              />
                            )}
                            {eventdet.attributes.Thumbnail.data === null && (
                              <img
                                src={require("../assets/images/sporting.jpeg")}
                                alt={eventdet.attributes.Name}
                                width={"255px"}
                                height={"357px"}
                              />
                            )}
                          </div>

                          <div className="details-banner-content offset-lg-3">
                            <h3 className="title">
                              {eventdet.attributes.Name || "Event Title"}
                            </h3>
                            <div className="tags">
                              <a>
                                Organized by:{" "}
                                <span>
                                  {eventdet.attributes.organizedby ||
                                    "Organizer Name"}
                                </span>
                              </a>
                            </div>

                            {revent && (
                              <>
                                {revent.map((event) => (
                                  <a className="button" key={event.id}>
                                    {
                                      event.attributes.event_range.data
                                        .attributes.Name
                                    }
                                  </a>
                                ))}
                              </>
                            )}

                            <div className="social-and-duration">
                              <div className="duration-area">
                                <div className="item">
                                  <i className="fas fa-calendar-alt"></i>
                                  <span>
                                    {moment(
                                      eventdet.attributes.startdate
                                    ).format("DD MMM, YYYY")}
                                  </span>
                                </div>
                                <div className="item">
                                  <i className="fa-solid fa-map-location-dot"></i>
                                  <span>
                                    {eventdet.attributes.location || "Location"}
                                  </span>
                                </div>
                              </div>

                              <ul className="social-share">
                                <div className="sharename">Share with</div>
                                <li>
                                  {eventdet.attributes.facebook ? (
                                    <a
                                      href={eventdet.attributes.facebook}
                                      target="_blank"
                                    >
                                      <i className="fa-brands fa-facebook"></i>
                                    </a>
                                  ) : (
                                    <a>
                                      <i className="fa-brands fa-facebook"></i>
                                    </a>
                                  )}
                                </li>
                                <li>
                                  {eventdet.attributes.twitter ? (
                                    <a
                                      href={eventdet.attributes.twitter}
                                      target="_blank"
                                    >
                                      <i className="fa-brands fa-x-twitter"></i>
                                    </a>
                                  ) : (
                                    <a>
                                      <i className="fa-brands fa-x-twitter"></i>
                                    </a>
                                  )}
                                </li>
                                <li>
                                  {eventdet.attributes.linkedin ? (
                                    <a
                                      href={eventdet.attributes.linkedin}
                                      target="_blank"
                                    >
                                      <i className="fa-brands fa-linkedin-in"></i>
                                    </a>
                                  ) : (
                                    <a>
                                      <i className="fa-brands fa-linkedin-in"></i>
                                    </a>
                                  )}
                                </li>
                                <li>
                                  {eventdet.attributes.instagram ? (
                                    <a
                                      href={eventdet.attributes.instagram}
                                      target="_blank"
                                    >
                                      <i className="fa-brands fa-instagram"></i>
                                    </a>
                                  ) : (
                                    <a>
                                      <i className="fa-brands fa-instagram"></i>
                                    </a>
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="book-section bg-one">
                      <div className="container">
                        <div className="book-wrapper offset-lg-3">
                          {isPastEvent ? (
                            <div className="left-side">
                              <div className="item">
                                <div className="item-header">
                                  <h5 className="title">4.5</h5>
                                  <div className="rated">
                                    <i className="fas fa-heart"></i>
                                    <i className="fas fa-heart"></i>
                                    <i className="fas fa-heart"></i>
                                    <i className="fas fa-heart"></i>
                                    <i className="fas fa-heart"></i>
                                  </div>
                                </div>
                                <p>Users Rating</p>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="left-side">
                                <ul className="countdown">
                                  <li>
                                    <h2>
                                      <span className="days">
                                        {String(timeLeft.days).padStart(2, "0")}
                                      </span>
                                    </h2>
                                    <p className="days_text">days</p>
                                  </li>
                                  <li>
                                    <h2>
                                      <span className="hours">
                                        {String(timeLeft.hours).padStart(
                                          2,
                                          "0"
                                        )}
                                      </span>
                                    </h2>
                                    <p className="hours_text">hrs</p>
                                  </li>
                                  <li>
                                    <h2>
                                      <span className="minutes">
                                        {String(timeLeft.minutes).padStart(
                                          2,
                                          "0"
                                        )}
                                      </span>
                                    </h2>
                                    <p className="minu_text">min</p>
                                  </li>
                                  <li>
                                    <h2>
                                      <span className="seconds">
                                        {String(timeLeft.seconds).padStart(
                                          2,
                                          "0"
                                        )}
                                      </span>
                                    </h2>
                                    <p className="seco_text">sec</p>
                                  </li>
                                </ul>
                              </div>
                              {localStorage.getItem("Token") !== null ? (
                                <Link
                                  to={`/booking/${id}`}
                                  className="custom-button"
                                >
                                  Book Now
                                </Link>
                              ) : (
                                <Link
                                  to={`/login`}
                                  className="custom-button"
                                >
                                  Book Now
                                </Link>
                              )}
                              
                            </>
                          )}
                        </div>
                      </div>
                    </section>
                  </>
                );
              })()}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default EventMain;

import React, { useEffect, useState } from "react";
import axios from "axios";
import baseUrl from "../Api";

export const Refund = () => {
    const [rterm, setRTerm] = useState("");

    useEffect(() => {
      const fetchTerms = async () => {
        try {
          const response = await axios.get(baseUrl + `/refund-policy`);
          if (response.data.data !== null) {
            setRTerm(response.data.data);
          }
        } catch (error) {
          console.error("Error fetching :", error);
        }
      };
      fetchTerms();
    }, []);
  return (
    <div>
      <section className="terms-section"></section>

      <section className="movie-section padding-top padding-bottom bg-two">
        <div className="container">
          <div className="row flex-wrap-reverse justify-content-center">
            <h4>Refund Policy</h4>
          </div>
          {rterm && (
            <>
              {rterm.attributes.refund.map((item, index) => {
                return (
                  <div key={index}>
                    {item.children.map((child, childIndex) => {
                      if (child.type === "link") {
                        return (
                          <span key={childIndex} style={{ fontSize: "20px" }}>
                            <a href={child.url}>{child.text}</a>
                          </span>
                        );
                      }

                      return (
                        <span
                          key={childIndex}
                          style={{
                            fontSize: "20px",
                            textDecoration: `${
                              child.strikethrough ? "line-through" : ""
                            } ${child.underline ? "underline" : ""}`.trim(),
                            fontWeight: child.bold ? "bold" : undefined,
                            fontStyle: child.italic ? "italic" : undefined,
                          }}
                        >
                          {child.code ? <code>{child.text}</code> : child.text}
                        </span>
                      );
                    })}
                    <br />{" "}
                  </div>
                );
              })}
            </>
          )}
        </div>
      </section>
    </div>
  )
}

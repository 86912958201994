import React, { useState } from 'react';

const Popup = ({ onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup">
        <h2>Add Your Comments</h2>
        <form className='popupmodal-form'>
          <div className="form-group">
            <label>Rating:</label>
            <div className="star-rating">
              <span className="star" data-value="1">★</span>
              <span className="star" data-value="2">★</span>
              <span className="star" data-value="3">★</span>
              <span className="star" data-value="4">★</span>
              <span className="star" data-value="5">★</span>
            </div>
          </div>
          <div className='form-group'>
            <label>
              Title:
            </label>
            <input 
                type="text" 
                name="title" 
                value="" 
                onChange="" 
                required 
              />
          </div>
          <div className="form-group">
            <label for="comments">Comments:</label>
            <textarea id="comments" name="comments" rows="4" required></textarea>
          </div>
          <button type="submit" className='btn btn-success'>Add Comments</button>
        </form>
        
        <button className="popclose" onClick={onClose}>X</button>
      </div>
    </div>
  );
};

const Popupmain = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="app text-center">
      <button className='pop-button' onClick={openPopup}>Add Comments</button>
      {isPopupOpen && <Popup onClose={closePopup} />}
    </div>
  );
};

export default Popupmain;

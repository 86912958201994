import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import EventPage from "./pages/EventPage";
import EventBooking from "./pages/EventBooking";
import { CategoryEvents } from "./pages/CategoryEvents";
import { Terms } from "./components/Terms";
import { Privacy } from "./components/Privacy";
import { Refund } from "./components/Refund";
import { Login } from "./pages/Login";
import { Signup } from "./pages/Signup";
import { SuccessPage } from "./pages/SuccessPage";
import { MyBookingList } from "./pages/MyBookingList";
const basename = process.env.NODE_ENV === "production" ? "/" : "";
const App = () => {
  return (
    <Router basename={basename}>
      <div>
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/mybooking" element={<Home />} />
            <Route path="/bookinglist/:id" element={<MyBookingList />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/events/:id" element={<EventPage />} />
            <Route path="/booking/:id" element={<EventBooking />} />
            <Route path="/success/:id" element={<SuccessPage />} />
            <Route path="/categorywise/:id" element={<CategoryEvents />} />
            <Route path="/allevents" element={<CategoryEvents />} />
            <Route path="/termsandcondition" element={<Terms />} />
            <Route path="/privacypolicy" element={<Privacy />} />
            <Route path="/refundpolicy" element={<Refund />} />

          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;

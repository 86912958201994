import React from "react";
import { Link, useNavigate } from "react-router-dom";
import bannerImage from "../assets/images/apps/apps01.png"; // Adjust the path as necessary

const footerLogo = {
  width: "100%",
  maxWidth: "250px",
};

const handBg = {
  backgroundImage: `url(${bannerImage})`,
};

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="footer-section">
      <div className="newslater-section padding-bottom">
        <div className="container">
          <div className="apps-wrapper bg-one padding-top padding-bottom">
            <div className="bg_img apps-bg" style={handBg}></div>
            <div className="section-header-3">
              <span className="cate">get Sportingindia app</span>
              <h2 className="title">app download</h2>
            </div>
            <div className="row">
              <div className="col-lg-7 offset-lg-5">
                <div className="content">
                  <h5 className="title">MAKE YOUR SPORTS EASIER</h5>
                  <p>
                    As the top sports platform in the country, we offer the
                    expertise, services, and tools you need to make your shift
                    to mobile smooth, effortless, and enjoyable. Whether you're
                    tracking performance, engaging with your favorite moments,
                    or managing events, we've got you covered every step of the
                    way.
                  </p>
                  <ul className="app-button">
                    <li>
                      <a>
                        <img
                          src={require("../assets/images/apps/apps03.png")}
                          alt="apps"
                        />
                      </a>
                    </li>
                    <li>
                      <a>
                        <img
                          src={require("../assets/images/apps/apps02.png")}
                          alt="apps"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="counter--area">
                  <div className="counter-item">
                    <div className="d-flex justify-content-center align-items-center mb-10">
                      <h4
                        className="odometer title"
                        data-odometer-final="50"
                      ></h4>
                      <h4 className="title">K+</h4>
                    </div>
                    <p className="info">apps downloads</p>
                  </div>
                  <div className="counter-item">
                    <div className="d-flex justify-content-center align-items-center mb-10">
                      <h4
                        className="odometer title"
                        data-odometer-final="15"
                      ></h4>
                      <h4 className="title">K+</h4>
                    </div>
                    <p className="info">Events in a Year</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="footer-top">
          <div className="logo">
            <Link to="/">
              <img
                src={require("../assets/images/logo/logo.png")}
                alt="footer"
                style={footerLogo}
              />
            </Link>
          </div>
          <ul className="social-icons">
            <li>
              <a>
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a>
                <i className="fa-brands fa-x-twitter"></i>
              </a>
            </li>
            <li>
              <a>
                <i className="fab fa-linkedin"></i>
              </a>
            </li>
            <li>
              <a>
                <i className="fab fa-instagram"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-bottom">
          <div className="footer-bottom-area">
            <div className="left">
              <p>
                Copyright © 2024 | All Rights Reserved By <a>Sportingindia </a>
              </p>
            </div>
            <ul className="links">
              <li>
                <a onClick={() => navigate("/termsandcondition")}>
                  Terms Of Use
                </a>
              </li>
              <li>
                <a onClick={() => navigate("/privacypolicy")}>Privacy Policy</a>
              </li>
              <li>
                <a onClick={() => navigate("/refundpolicy")}>Refund Policy</a>
              </li>
              <li>
                <a>FAQ</a>
              </li>
              <li>
                <a>Feedback</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

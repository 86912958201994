import React, { useEffect, useState } from "react";
import Popupmain from "../components/Popupmain";
import EventMain from "../components/EventMain";
import { useParams } from "react-router-dom";
import baseUrl from "../Api";
import axios from "axios";

const EventPage = () => {
  const { id } = useParams();
  const [eventdet, setEventDet] = useState("");
  const [revent, setREvent] = useState([]);
  const [amenity, setAmenity] = useState([]);

  const [rterm, setRTerm] = useState("");
  const [elist, setEList] = useState([]);

  useEffect(() => {
    const fetchTerms = async () => {
      setLoading(true);
      try {
        const response = await axios.get(baseUrl + `/terms-and-condition`);
        if (response.data.data !== null) {
          setRTerm(response.data.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching :", error);
      }
    };
    const fetchRelated = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          baseUrl +
            "/events?populate=*&pagination[page]=1&pagination[pageSize]=10"
        );
        if (response.data.data !== null) {
          setEList(response.data.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching :", error);
      }
    };
    fetchRelated();
    fetchTerms();
    fetchEvent();
  }, [id]);

  const fetchEvent = async () => {
    setREvent([]);
    setAmenity([]);
    setLoading(true);
    try {
      const response = await fetch(baseUrl + `/events/${id}?populate=*`);
      const data = await response.json();
      if (data.data !== null) {
        setEventDet(data.data);
        const eventRangeAmounts = data.data.attributes.event_range_amounts.data;

        if (eventRangeAmounts && eventRangeAmounts.length > 0) {
          const newREventData = [];
          for (let i = 0; i < eventRangeAmounts.length; i++) {
            const response = await fetch(
              `${baseUrl}/event-range-amounts/${eventRangeAmounts[i].id}?populate=*`
            );
            const data1 = await response.json();

            if (data1.data) {
              newREventData.push(data1.data); // Append new data to existing state
            }
          }
          setREvent((prevREvent) => [...prevREvent, ...newREventData]);
        }

        const eventAmenity = data.data.attributes.event_amenities.data;

        if (eventAmenity && eventAmenity.length > 0) {
          const newAmenity = [];
          for (let i = 0; i < eventAmenity.length; i++) {
            const response = await fetch(
              `${baseUrl}/event-amenities/${eventAmenity[i].id}?populate=*`
            );
            const data1 = await response.json();

            if (data1.data) {
              newAmenity.push(data1.data); // Append new data to existing state
            }
          }
          setAmenity((prevREvent) => [...prevREvent, ...newAmenity]);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const [loading, setLoading] = useState(false);

  return (
    <div>
      {loading && (
        <div className="preloader">
          <div className="preloader-inner">
            <div className="preloader-icon">
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      )}

      {!loading && (
        <>
          <EventMain />

          <section className="movie-details-section padding-top padding-bottom">
            <div className="container">
              {eventdet && (
                <div className="row justify-content-center flex-wrap-reverse mb--50">
                  <div className="col-lg-3 col-sm-10 col-md-6 mb-50">
                    <div className="widget-1 widget-offer">
                      <h3 className="title">Related Events</h3>
                      <div className="offer-body">
                        {elist.map((item) => {
                          if (id != item.id) {
                            return (
                              <div className="offer-item" key={item.id}>
                                {item.attributes.Banner.data !== null && (
                                  <a>
                                    <img
                                      src={`https://strapi.sportingindia.com${
                                        item.attributes.Banner?.data?.attributes
                                          ?.url ||
                                        require("../assets/images/sporting.jpeg")
                                      }`}
                                      alt={item.attributes.Name}
                                      title={item.attributes.Name}
                                    />
                                  </a>
                                )}
                                {item.attributes.Banner.data === null && (
                                  <a>
                                    <img
                                      src={require("../assets/images/sporting.jpeg")}
                                      alt={item.attributes.Name}
                                      title={item.attributes.Name}
                                    />
                                  </a>
                                )}
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                    <div className="widget-1 widget-banner">
                      <div className="widget-1-body">
                        <a>
                          <img
                            src={require("../assets/images/sidebar/banner/banner02.jpg")}
                            alt="banner"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9 mb-50">
                    <div className="movie-details">
                      <div className="tab summery-review">
                        <ul className="tab-menu">
                          <li className="active">summary</li>
                          {/* <li>
                  user review <span>147</span>
                </li> */}
                        </ul>
                        <div className="tab-area">
                          <div className="tab-item active">
                            <div className="item">
                              <h5 className="sub-title">Description</h5>
                              {eventdet.attributes.information && (
                                <>
                                  {eventdet.attributes.information.map(
                                    (item, index) => {
                                      return (
                                        <div key={index}>
                                          {item.children.map(
                                            (child, childIndex) => {
                                              if (child.type === "link") {
                                                return (
                                                  <span
                                                    key={childIndex}
                                                    style={{ fontSize: "20px" }}
                                                  >
                                                    <a href={child.url}>
                                                      {child.text}
                                                    </a>
                                                  </span>
                                                );
                                              }

                                              return (
                                                <span
                                                  key={childIndex}
                                                  style={{
                                                    fontSize: "20px",
                                                    textDecoration: `${
                                                      child.strikethrough
                                                        ? "line-through"
                                                        : ""
                                                    } ${
                                                      child.underline
                                                        ? "underline"
                                                        : ""
                                                    }`.trim(),
                                                    fontWeight: child.bold
                                                      ? "bold"
                                                      : undefined,
                                                    fontStyle: child.italic
                                                      ? "italic"
                                                      : undefined,
                                                  }}
                                                >
                                                  {child.code ? (
                                                    <code>{child.text}</code>
                                                  ) : (
                                                    child.text
                                                  )}
                                                </span>
                                              );
                                            }
                                          )}
                                          <br />{" "}
                                        </div>
                                      );
                                    }
                                  )}
                                </>
                              )}
                            </div>
                            {revent.length !== 0 && (
                              <div className="item">
                                <h5 className="sub-title">Pricing</h5>
                                <div className="movie-facility">
                                  <div className="row">
                                    {revent.map((item, index) => {
                                      const ageCategoryNames =
                                        item.attributes.age_categories.data
                                          .map(
                                            (category) =>
                                              category.attributes.name
                                          )
                                          .join(", ");

                                      return (
                                        <div className="col-lg-3" key={index}>
                                          <div className="booking-summery bg-one">
                                            <h4 className="title">
                                              {" "}
                                              {
                                                item.attributes.event_range.data
                                                  .attributes.Name
                                              }
                                            </h4>{" "}
                                            {/* Display the km */}
                                            <ul className="side-shape">
                                              <li>
                                                <span className="info">
                                                  <span>Age Limit</span>
                                                </span>
                                                <h6 className="subtitle">
                                                  <span>
                                                    {ageCategoryNames
                                                      ? ageCategoryNames
                                                      : "-"}
                                                  </span>
                                                </h6>
                                              </li>
                                            </ul>
                                            <ul>
                                              <li>
                                                <span className="info">
                                                  <span>Price</span>
                                                  {item.attributes.amount ===
                                                    0 ||
                                                  item.attributes.amount ===
                                                    null ? (
                                                    <span>Free</span>
                                                  ) : (
                                                    <span>
                                                      ₹ {item.attributes.amount}
                                                    </span>
                                                  )}
                                                </span>

                                                {item.attributes.amount === 0 ||
                                                item.attributes.amount ===
                                                  null ? (
                                                  <span></span>
                                                ) : (
                                                  <span className="info">
                                                    {item.attributes.GST > 0 &&
                                                      item.attributes.GST !==
                                                        null && (
                                                        <>
                                                          <span>GST</span>
                                                          <span>
                                                            {
                                                              item.attributes
                                                                .GST
                                                            }{" "}
                                                            %
                                                          </span>
                                                        </>
                                                      )}
                                                  </span>
                                                )}
                                              </li>
                                            </ul>
                                          </div>
                                          <div className="proceed-area text-center">
                                            <h6 className="subtitle">
                                              <span>Amount Payable :</span>
                                              {item.attributes.amount === 0 ||
                                              item.attributes.amount ===
                                                null ? (
                                                <span>Free</span>
                                              ) : (
                                                <span>
                                                  ₹ {item.attributes.amount}
                                                  {item.attributes.GST > 0 &&
                                                    item.attributes.GST !==
                                                      null && (
                                                      <>
                                                        {" "}
                                                        + GST{" "}
                                                        {item.attributes.GST} %
                                                      </>
                                                    )}
                                                </span>
                                              )}

                                              {/* Calculate total payable */}
                                            </h6>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            )}
                            {amenity.length !== 0 && (
                              <div className="item">
                                <div className="header">
                                  <h5 className="sub-title">
                                    Highlighted Amenities
                                  </h5>
                                </div>
                                <div className="row">
                                  {amenity.map((item, index) => {
                                    return (
                                      <div
                                        className="cast-item col-md-2"
                                        key={index}
                                      >
                                        <div className="cast-thumb">
                                          <a>
                                            {item.attributes.image.data !==
                                              null && (
                                              <img
                                                src={`https://strapi.sportingindia.com${
                                                  item.attributes.image?.data
                                                    ?.attributes?.url ||
                                                  require("../assets/images/sporting.jpeg")
                                                }`}
                                                alt={item.attributes.amenity}
                                                width={"50px"}
                                                height={"50px"}
                                              />
                                            )}
                                            {item.attributes.image.data ===
                                              null && (
                                              <img
                                                src={require("../assets/images/sporting.jpeg")}
                                                alt={item.attributes.amenity}
                                                width={"50px"}
                                                height={"50px"}
                                              />
                                            )}

                                            {/* <img
         src={require("../assets/images/icons/t-shirt.png")}
         alt="cast"
       /> */}
                                          </a>
                                        </div>
                                        <div className="cast-content">
                                          <h6 className="cast-title">
                                            <a>{item.attributes.amenity}</a>
                                          </h6>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}

                            <div className="item">
                              <h5 className="sub-title">
                                Terms &amp; Conditions
                              </h5>
                              {rterm && (
                                <>
                                  {rterm.attributes.terms.map((item, index) => {
                                    return (
                                      <div key={index}>
                                        {item.children.map(
                                          (child, childIndex) => {
                                            if (child.type === "link") {
                                              return (
                                                <span
                                                  key={childIndex}
                                                  style={{ fontSize: "20px" }}
                                                >
                                                  <a href={child.url}>
                                                    {child.text}
                                                  </a>
                                                </span>
                                              );
                                            }

                                            return (
                                              <span
                                                key={childIndex}
                                                style={{
                                                  fontSize: "20px",
                                                  textDecoration: `${
                                                    child.strikethrough
                                                      ? "line-through"
                                                      : ""
                                                  } ${
                                                    child.underline
                                                      ? "underline"
                                                      : ""
                                                  }`.trim(),
                                                  fontWeight: child.bold
                                                    ? "bold"
                                                    : undefined,
                                                  fontStyle: child.italic
                                                    ? "italic"
                                                    : undefined,
                                                }}
                                              >
                                                {child.code ? (
                                                  <code>{child.text}</code>
                                                ) : (
                                                  child.text
                                                )}
                                              </span>
                                            );
                                          }
                                        )}
                                        <br />{" "}
                                      </div>
                                    );
                                  })}
                                </>
                              )}
                            </div>
                          </div>

                          <div className="tab-item">
                            <div className="">
                              <Popupmain />
                            </div>
                            <div className="movie-review-item">
                              <div className="author">
                                <div className="thumb">
                                  <a>
                                    <img
                                      src={require("../assets/images/cast/cast02.jpg")}
                                      alt="cast"
                                    />
                                  </a>
                                </div>
                                <div className="movie-review-info">
                                  <h6 className="subtitle">
                                    <a>minkuk seo</a>
                                  </h6>
                                  <span className="reply-date">
                                    13 Days Ago
                                  </span>
                                </div>
                              </div>
                              <div className="movie-review-content">
                                <div className="review">
                                  <i className="flaticon-favorite-heart-button"></i>
                                  <i className="flaticon-favorite-heart-button"></i>
                                  <i className="flaticon-favorite-heart-button"></i>
                                  <i className="flaticon-favorite-heart-button"></i>
                                  <i className="flaticon-favorite-heart-button"></i>
                                </div>
                                <h6 className="cont-title">Awesome Movie</h6>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit. Integer volutpat enim non
                                  ante egestas vehicula. Suspendisse potenti.
                                  Fusce malesuada fringilla lectus venenatis
                                  porttitor.{" "}
                                </p>
                                <div className="review-meta">
                                  <a>
                                    <i className="flaticon-hand"></i>
                                    <span>8</span>
                                  </a>
                                  <a className="dislike">
                                    <i className="flaticon-dont-like-symbol"></i>
                                    <span>0</span>
                                  </a>
                                  <a>Report Abuse</a>
                                </div>
                              </div>
                            </div>
                            <div className="movie-review-item">
                              <div className="author">
                                <div className="thumb">
                                  <a>
                                    <img
                                      src={require("../assets/images/cast/cast04.jpg")}
                                      alt="cast"
                                    />
                                  </a>
                                </div>
                                <div className="movie-review-info">
                                  <h6 className="subtitle">
                                    <a>rudra rai</a>
                                  </h6>
                                  <span className="reply-date">
                                    13 Days Ago
                                  </span>
                                </div>
                              </div>
                              <div className="movie-review-content">
                                <div className="review">
                                  <i className="flaticon-favorite-heart-button"></i>
                                  <i className="flaticon-favorite-heart-button"></i>
                                  <i className="flaticon-favorite-heart-button"></i>
                                  <i className="flaticon-favorite-heart-button"></i>
                                  <i className="flaticon-favorite-heart-button"></i>
                                </div>
                                <h6 className="cont-title">Awesome Movie</h6>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit. Integer volutpat enim non
                                  ante egestas vehicula. Suspendisse potenti.
                                  Fusce malesuada fringilla lectus venenatis
                                  porttitor.{" "}
                                </p>
                                <div className="review-meta">
                                  <a>
                                    <i className="flaticon-hand"></i>
                                    <span>8</span>
                                  </a>
                                  <a className="dislike">
                                    <i className="flaticon-dont-like-symbol"></i>
                                    <span>0</span>
                                  </a>
                                  <a>Report Abuse</a>
                                </div>
                              </div>
                            </div>
                            <div className="movie-review-item">
                              <div className="author">
                                <div className="thumb">
                                  <a>
                                    <img
                                      src={require("../assets/images/cast/cast01.jpg")}
                                      alt="cast"
                                    />
                                  </a>
                                </div>
                                <div className="movie-review-info">
                                  <h6 className="subtitle">
                                    <a>rafuj</a>
                                  </h6>
                                  <span className="reply-date">
                                    13 Days Ago
                                  </span>
                                </div>
                              </div>
                              <div className="movie-review-content">
                                <div className="review">
                                  <i className="flaticon-favorite-heart-button"></i>
                                  <i className="flaticon-favorite-heart-button"></i>
                                  <i className="flaticon-favorite-heart-button"></i>
                                  <i className="flaticon-favorite-heart-button"></i>
                                  <i className="flaticon-favorite-heart-button"></i>
                                </div>
                                <h6 className="cont-title">Awesome Movie</h6>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit. Integer volutpat enim non
                                  ante egestas vehicula. Suspendisse potenti.
                                  Fusce malesuada fringilla lectus venenatis
                                  porttitor.{" "}
                                </p>
                                <div className="review-meta">
                                  <a>
                                    <i className="flaticon-hand"></i>
                                    <span>8</span>
                                  </a>
                                  <a className="dislike">
                                    <i className="flaticon-dont-like-symbol"></i>
                                    <span>0</span>
                                  </a>
                                  <a>Report Abuse</a>
                                </div>
                              </div>
                            </div>
                            <div className="movie-review-item">
                              <div className="author">
                                <div className="thumb">
                                  <a>
                                    <img
                                      src={require("../assets/images/cast/cast03.jpg")}
                                      alt="cast"
                                    />
                                  </a>
                                </div>
                                <div className="movie-review-info">
                                  <h6 className="subtitle">
                                    <a>bela bose</a>
                                  </h6>
                                  <span className="reply-date">
                                    13 Days Ago
                                  </span>
                                </div>
                              </div>
                              <div className="movie-review-content">
                                <div className="review">
                                  <i className="flaticon-favorite-heart-button"></i>
                                  <i className="flaticon-favorite-heart-button"></i>
                                  <i className="flaticon-favorite-heart-button"></i>
                                  <i className="flaticon-favorite-heart-button"></i>
                                  <i className="flaticon-favorite-heart-button"></i>
                                </div>
                                <h6 className="cont-title">Awesome Movie</h6>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit. Integer volutpat enim non
                                  ante egestas vehicula. Suspendisse potenti.
                                  Fusce malesuada fringilla lectus venenatis
                                  porttitor.{" "}
                                </p>
                                <div className="review-meta">
                                  <a>
                                    <i className="flaticon-hand"></i>
                                    <span>8</span>
                                  </a>
                                  <a className="dislike">
                                    <i className="flaticon-dont-like-symbol"></i>
                                    <span>0</span>
                                  </a>
                                  <a>Report Abuse</a>
                                </div>
                              </div>
                            </div>
                            <div className="load-more text-center">
                              <a className="custom-button">load more</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default EventPage;

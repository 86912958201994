import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  return (
    <header className="header-section">
      <div className="container">
        <div className="header-wrapper">
          <div className="logo">
            <Link to="/">
              <img src={require("../assets/images/logo/logo.png")} alt="logo" />
            </Link>
          </div>
          <div className="header-right">
            <ul className="menu">
              {localStorage.getItem("UID") === null && (
                <li className="header-button pr-0">
                  <Link to={"/login"}>Signin</Link>
                </li>
              )}
            </ul>
            {localStorage.getItem("UID") !== null && (
              <a
                className="btn btn-success me-2"
                onClick={() => navigate("/mybooking")}
              >
                My Booking
              </a>
            )}
            {localStorage.getItem("UID") !== null && (
              <a
                className="btn btn-danger"
                onClick={() => {
                  localStorage.clear();
                  navigate("/");
                  window.location.reload();
                }}
              >
                Logout
              </a>
            )}
            {/* <div className="header-bar">
              <span></span>
              <span></span>
              <span></span>
            </div> */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
